import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import Hero from "../../../../components/hero";
import ContactPerson from "../../../../components/contactPerson";
import { CaseList } from "../../../../components/caseList.en";
import { Facts } from "../../../../components/facts";
import { Count } from "../../../../components/count";
import { Container } from "../../../../components/container";
import { ServicesTeaser } from "../../../../components/servicesTeaser";
import { PartnersGrid } from "../../../../components/partnersGrid";

const CrownpeakPartnership = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-crownpeak.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      rene: file(relativePath: { eq: "profiles/rene-zoller.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      crownpeak: file(relativePath: { eq: "partner/crownpeak-partner.png" }) {
        ...pngTracedLogo
      }
      crownpeakPremier: file(
        relativePath: { eq: "partner/crownpeak-partner_premier.png" }
      ) {
        ...pngTracedLogo
      }
      crownpeakTechnology: file(
        relativePath: { eq: "partner/crownpeak-partner_technology.png" }
      ) {
        ...pngTracedLogo
      }
      crownpeakQAP: file(
        relativePath: { eq: "partner/crownpeak-partner_qap.png" }
      ) {
        ...pngTracedLogo
      }
    }

    fragment pngTracedLogo on File {
      childImageSharp {
        gatsbyImageData(width: 380, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  `);

  return (
    <Layout lang="en" langPath="partner.crownpeak.link">
      <Seo
        title="FirstSpirit Implementation Partner – Monday Consulting"
        description={
          'As a "Premier Partner" of Crownpeak, we are among the best-trained consultants for the FirstSpirit Digital Experience Platform.'
        }
        keywords="FirstSpirit, Crownpeak, Implementation Partner, CMS, Premier Partner, e-Commerce"
      />
      <div className="parallax-translate bg-shape-dots"></div>
      <Hero
        headline="FirstSpirit – the hybrid CMS from Crownpeak"
        subline="We use the innovative DXP from Crownpeak to develop made-to-measure solutions that we integrate into existing systems."
        section="FirstSpirit Partner"
        styleClass="mb-lg-5"
        bgColor="green"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Crownpeak"
        />
      </Hero>

      <div className="container mb-4 mb-md-5 mt-md-5 my-5">
        <div className="row flex-row-reverse">
          <div className="col-12 col-md-8 mb-4">
            <h2>A content-focused e-commerce CMS</h2>
            <p>
              The FirstSpirit DXP from Crownpeak transforms your customers into
              cheerleaders for your brand. Thanks to its hybrid headless
              architecture, personalized content is even quicker to create and
              then deploy over any channel as required. This lets you accompany
              your clientele throughout their customer journey and strengthen
              long-term customer loyalty. The headless e-commerce CMS breathes
              new life into your product catalog, while AI-based tools help to
              personalise your customer interactions and experiences.
            </p>
            <p>
              Drawing on our long-standing cooperation with Crownpeak and a
              string of shared solutions we have developed together, we are part
              of the small group of "Premier Partners" worldwide. We’re also in
              the vanguard of global consultants for the FirstSpirit Digital
              Experience Platform and look forward to providing you with our
              specialized expertise – especially in complex projects – as an
              Implementation Partner.
            </p>
            <p>
              From targeted customer engagement and e-commerce strategies to
              mobile and responsive designs: we use FirstSpirit as the basis for
              developing a fresh, individual and up-to-date CMS solution with a
              key focus on outstanding usability – whether cloud-based or hosted
              on-premises.
            </p>
            <p>
              With the "Quality-Approved Project" status, we offer a rock-solid
              quality guarantee jointly with Crownpeak that our customers can
              rely on for the implementation of their project.
            </p>
          </div>
          <div className="col-12 col-md-4 px-4 d-flex flex-column align-items-center">
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.crownpeak.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="Crownpeak"
              />
            </div>
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.crownpeakPremier.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="FirstSpirit Premier Partner by Crownpeak"
                title="FirstSpirit Premier Partner by Crownpeak"
              />
            </div>
            <div className="w-75 mb-3">
              <GatsbyImage
                image={
                  images.crownpeakTechnology.childImageSharp.gatsbyImageData
                }
                className="w-100"
                alt="FirstSpirit Technology Partner by Crownpeak"
                title="FirstSpirit Technology Partner by Crownpeak"
              />
            </div>
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.crownpeakQAP.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="FirstSpirit Quality Approved Project by Crownpeak"
                title="FirstSpirit Quality Approved Projec by Crownpeakt"
              />
            </div>
          </div>
        </div>
      </div>

      <Container shape="square">
        <div className="container mt-6 mb-4 mb-md-5">
          <h2 className="col-12 text-center">
            Good reasons for Monday Consulting and FirstSpirit
          </h2>
          <Facts>
            <Count count="100" suffix="%">
              Complete set of FirstSpirit certification.
            </Count>
            <Count count="2021">
              Monday Consulting is awarded "Premier Partner" status after a
              decade of successful cooperation.
            </Count>
            <Count count="30" prefix=">">
              We’ve completed FirstSprit projects for over 30 satisfied
              customers.
            </Count>
          </Facts>
        </div>
      </Container>

      <div className="container">
        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-cross bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              1
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Headless architecture
            </h2>
            <h3>Content-driven e-commerce</h3>
            <p>
              The headless architecture gives you the chance to inspire your
              clientele with unique, high-quality content at any waypoint along
              the customer journey, and generate customer loyalty both online
              and offline. Combine the advantages of the digital and analog
              worlds to create a seamless shopping experience. These first-hand
              experiences are then complemented with personalized storytelling,
              great usability and always-on availability.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-dots bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              2
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Personalized content</h2>
            <h3>FirstSpirit Intelligent Content Engine</h3>
            <p>
              Use the FirstSpirit Intelligent Content Engine (ICE) to give your
              customers personalized, relevant content that targets their needs
              and accelerates purchase decision-making. Our ICE-certified team
              is ready to advise you on personalization strategies, and help you
              secure and strengthen your digital customer communications.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-circle bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              3
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Heterogeneous system landscapes
            </h2>
            <h3>FirstSpirit DXP as integration platform</h3>
            <p>
              Our FirstSpirit experts integrate your existing applications even
              in complex system landscapes into the FirstSpirit Digital
              Experience Platform. In this way, you can continue to use any CRM
              or store system and create a unique user experience for your
              customers.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-square bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              4
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Professional form management
            </h2>
            <h3>Formcentric for FirstSpririt</h3>
            <p>
              Our form manager and its Analytics add-on have earned us the
              distinction of being an "FirstSpirit Technology Partner".
              Formcentric now has a large installed base as a centralized
              integration platform for customer form data. With Formcentric, you
              can extend your web presence to include professional HTML forms
              that blend effortlessly with your design. Letting you create a
              one-of-a-kind customer experience in no time at all from the
              familiar FirstSpirit ContentCreator environment.
            </p>
            <a
              href="https://formcentric.com/en/"
              target="_blank"
              className="btn mt-1"
              rel="noopener noreferrer"
            >
              More about Formcentric
            </a>
          </div>
        </div>
      </div>

      <PartnersGrid
        title="Specialization for your success"
        teaser="With our strong partnerships, we create accessible and individual experiences of tomorrow."
      />

      <ServicesTeaser
        title="Discover our possibilities"
        text={
          "With our expertise in strategy and conception, we work together to create high-quality solutions that meet tomorrow's standards today.\nWe provide comprehensive services, encompassing the selection of the most suitable content management system, unique design and creation, and optimization of front-end, back-end, and infrastructure, all from a single source.\n\nWe guide you through the entire process and provide first-class customer care even after the project has been completed."
        }
        ctaLink="/en/services/serviceportfolio"
        ctaText="Our portfolio of services"
      />

      <div
        className="content-container mt-5 vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Selected FirstSpirit case studies</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList category="firstspirit" count="2" />
      </div>

      <ContactPerson
        headline="Entrust your FirstSpirit project to our experienced hands."
        text="No challenge is too big for our specialists to tackle. Talk to us today about your needs and requirements."
        contactEmail="firstspirit@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="René Zoller, Unit Lead FirstSpirit"
      >
        <GatsbyImage
          image={images.rene.childImageSharp.gatsbyImageData}
          alt="René Zoller"
        />
      </ContactPerson>
    </Layout>
  );
};

export default CrownpeakPartnership;
